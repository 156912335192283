import './index.css';

import React from 'react';
import axios from 'axios';
//import { config } from "../utils/web-office-sdk-v1.1.19.es.js";
//import WebOfficeSDK from './utils/web-office-sdk-solution.es.js'
import { config } from "../utils/web-office-sdk-solution-v1.1.27.es.js";
import { Den } from '@fewbox/den-web';
import { ReactComponent as LogoSvg } from '../svgs/logo.svg';
import { ReactComponent as TempurSealySvg } from '../svgs/tempur-sealy.svg';
import { ReactComponent as LenovoSvg } from '../svgs/lenovo.svg';
import { ReactComponent as SonySvg } from '../svgs/sony.svg';

const wx = window.wx;

const Main = (props) => {

  // wx.miniProgram.postMessage({ data: {foo: 'bar'} })

  const refreshToken = async () => {
    // 自身业务处理...
    // 可以返回 Promise 或者 return { token, timeout }
    const {data: {
      expires_in, token
    }} = await axios.get(`https://api.yingoukj.cn/getUrlAndToken?fileid=&filename=&fileurl=`);
    return {
      token: token, // 必需：你需要设置的 token
      timeout: expires_in, //  必需：token 超时时间，以 10 分钟示例
    };
  };
  const iframeRef = React.useRef(null);

  const hideCommandBars = (app, names) => {
    names.forEach(async (name)=>{
      const commandBar = await app.CommandBars(name);
      commandBar.Visible = false;
    });
  }

  const init = async (fileId, fileName, fileUrl, pageIndex, platform) => {
    // const {data} = await axios.get("http://82.157.243.144:6443/getWXAccToken");
    // const {data} = await axios.get("https://api.yingoukj.cn/getWXAccToken");
    // console.log("fileUrl", fileUrl);
    // setWxToken(data.wx_acc_token);
    const {data: {
      expires_in, token, wpsUrl
    }} = await axios.get(`https://api.yingoukj.cn/getUrlAndToken?fileid=${fileId}&filename=${fileName}&fileurl=${fileUrl}`);
    // const {data: {
    //   expires_in, token, wpsUrl
    // }} = await axios.get("http://82.157.243.144:6443/getUrlAndToken?fileid=3007808831");

    const jssdk = config({
      url: wpsUrl, // 该地址需要后端提供，https://wwo.wps.cn/office/p/xxx,
      mount: document.querySelector('.mount-container'),
      refreshTokenWrapper: refreshToken,
      pptOptions: {
        isShowBottomStatusBar: false, // 是否展示底部状态栏
        isShowRemarkView: false, // 是否显示备注视图
        isShowInsertMedia: false, // 是否显示插入音视频入口
        isShowComment: false, // 是否显示评论相关入口
      }
    });
    await jssdk.on('fileOpen', async (data) => {
      const { fileInfo: {officeType} } = data;
      timerRec.current = Date.now();

      await jssdk.ready();
      const app = jssdk.Application;
      //console.log('ENV', platform, officeType);
      if(platform == 'windows' || platform == 'mac') {
        // PC
        hideCommandBars(app, ['MoreMenus', 'TabPrintPreview', 'FloatQuickHelp', 'HistoryRecord',
        'HeaderLeft', 'HeaderMiddle', 'HeaderRight', 'HistoryVersion', 'ShareLink']);
        if(officeType === 'f'){
          hideCommandBars(app, ['StartTab','AnnoTab','TabStartTab','TabAnnoTab','TaskPane']);
        }
        else if(officeType === 'p'){
          hideCommandBars(app, ['InsertTab','ReviewTab','TabStartTab','TabInsertTab','TabReviewTab']);
        }
        else if(officeType === 'w'){
          hideCommandBars(app, ['StartTab', 'InsertTab', 'ReviewTab', 'PageTab', 'TabStartTab', 'TabInsertTab',
          'TabPageTab', 'TabReviewWord', 'TaskPane', 'ContextMenuConvene', 'ReviewTrackChanges', 'BookMark',
          'PanelBookMark', 'RightMenuHistory', 'RevisionSetting', 'BookMarkContextMenu', 'TextMenuSaveImage',
          'TextMenu', 'ShapeContextMenu']);
        }
        else {
          // Do Nothing
        }
      }
      else {
        // Mobile
        hideCommandBars(app, ['Cooperation', 'More', 'Logo', 'SendButton', 'Invitation']);
        if(officeType === 'f'){
          hideCommandBars(app, ['Meeting', 'PDFMobilePageBar']);
        }
        else if(officeType === 'p'){
          hideCommandBars(app, ['WPPMobileCommentButton', 'WPPMobileMarkButton', 'property', 'insert', 'comment',
          'keyboard', 'InsertImage', 'WPPMobileComponentToolbar', 'WPPMobileThumbnailContainer', 'WPPMobileTimeStamp',
          'FloatMenuDownloadImage']);
        }
        else if(officeType === 'w'){
          hideCommandBars(app, ['WriterHoverToolbars', 'Meeting', 'Print', 'FontTab', 'Arrange', 'Insert', 'Review',
          'Keyboard', 'InsertImage']);
        }
        else {
          // Do Nothing
        }
      }
      // Mobile
      //hideCommandBars(app, ['Cooperation', 'More', 'Logo', 'SendButton','Invitation']);
      /*if(fileName.endsWith('pdf')){
        //await app.ActivePDF.StartPlay('active', true, false);
        const pdf = await app.ActivePDF;
        pdf.PlayMode = 'start';
      }*/
      if(pageIndex) {
        await app.ActivePDF.JumpToPage(parseInt(pageIndex)); 
      }

      // const waterMarks = await app.ActiveDocument.Sections.Item(1).WaterMarks;
      // console.log("waterMarks", waterMarks);
      // waterMarks?.DeleteWaterMark();
      if (officeType === 'w') {
        setActiveIndesCB(1);
        jssdk.ApiEvent.AddApiEventListener("WindowScrollChange", (data) => {
          const {Data: {scrollTop, clientHeight}} = data;
          const pageIdx = Math.floor(scrollTop / clientHeight) + 1;
          if(pageIdx === activeIndex) return;
          setActiveIndesCB(pageIdx)
        });
        const WriterHoverToolbars = await app.CommandBars('WriterHoverToolbars');
        WriterHoverToolbars.Visible = false;
      }
      if(officeType === 'f') {
        setActiveIndesCB(1);
        //监听PDF页码变化, word好像不行
        jssdk.ApiEvent.AddApiEventListener("CurrentPageChange", (data) => {
          setActiveIndesCB(data + 1)
        });
      }
      if (officeType === 'p') {
    // // 监听幻灯片Active Slice事件
        jssdk.ApiEvent.AddApiEventListener('ActiveSlideChange', (e) => {
          const { Data: {slideIndex, finished }} = e 
          setActiveIndesCB(slideIndex + 1) // 似乎是从零开始的
        })
      };

      // setInterval(() => {
      //   wx.miniProgram.postMessage({ data: {
      //     ...pageStayTime
      //   }});
      // }, 2000);

    });
    jssdk.setToken({token: token})
    iframeRef.current = jssdk.iframe;
    // await jssdk.ready();
    // const events = await jssdk.Events;
    // const app = jssdk.Application;

    
    // const waterMarks = await app.ActiveDocument.Sections.Item(1).WaterMarks;

    // // 插入文字水印
    // await waterMarks.AddTextWaterMark({
    //   Text: '水印文本', // Text：水印文本
    //   FontName: '宋体', // FontName：水印字体类型
    //   FontSize: 40, // FontSize：水印字体大小
    //   FontColor: '#171717', // FontColor：水印字体颜色
    //   Transparency: 0.3, // Transparency：透明度
    //   Gradient: false, // Gradient：倾斜度
    //   ApplyTo: 1, // ApplyTo：插入位置
    // });
    // waterMarks.DeleteWaterMark();
    // 公共
    // const waterMarks = await app.ActiveDocument.Sections.Item(1).WaterMarks;
    // console.log("waterMarks", waterMarks);
    // // 获取水印
    // const waterMark = await waterMarks.Item(1);
    // console.log("获取水印", waterMark);
  
    // 设置水印的透明度
    // waterMark.Transparency = 0;

    // jssdk.ApiEvent.AddApiEventListener('SlideShowOnNext', (e) => {
    //   console.log("SlideShowOnNext SlideShowOnNext==================", e)
    //   const { Data: {slideIndex }} = e
    // })
    
    
    // jssdk.ApiEvent.AddApiEventListener('SlideShowOnPrevious', (e) => {
    //   console.log("SlideShowOnPrevious SlideShowOnPrevious==================", e)
    //   const { Data: {slideIndex }} = e
    // })

    // jssdk.ApiEvent.AddApiEventListener('SlideShowOnFirst', (e) => {
    //   console.log("SlideShowOnFirst SlideShowOnFirst==================", e)
    //   const { Data: {slideIndex }} = e
    // })
  
    // jssdk.ApiEvent.AddApiEventListener('SlideSelectionChanged', (e) => {
    //   setSelectedIndex(e)
    // })
  }


  // const [selectedIndex, setSelectedIndex] = React.useState(1);
  // const [wxToken, setWxToken] = React.useState("");
  // const [fileID, setFileId] = React.useState("");


  const [pageStayTime, setPageStayTime] = React.useState({});

  const timerRec = React.useRef(null);
  const lastPageRec = React.useRef(0);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const setActiveIndesCB = (activeIndex) => {
    setActiveIndex((prev) => {
      if(!prev){
        lastPageRec.current = activeIndex;
      } else {
        lastPageRec.current = prev;
      }
      return activeIndex
    })
  };

  const setPageStayTimeCB = () => {
    if (!activeIndex || !timerRec.current) return; // 文档没加载完
    const tempRec = {...pageStayTime}
    if(tempRec[activeIndex] === undefined) {
      tempRec[activeIndex] = 0;
    }
    if(tempRec[lastPageRec.current] !== undefined) {
      tempRec[lastPageRec.current] = (tempRec[lastPageRec.current] + (Date.now() - timerRec.current)) / 1000;
    }

    setPageStayTime({
      // ...pageStayTime,
      ...tempRec,
    })
    wx.miniProgram.postMessage({ data: {
      // ...pageStayTime,
      ...tempRec,
      [activeIndex]: tempRec[activeIndex] + (Date.now() - timerRec.current) / 1000,
    }});
    lastPageRec.current = activeIndex;
    timerRec.current = Date.now();
  };

  const getQueryPramas = () => {
    const queryString = window.location.search;
    //window.document.write(window.location.href);
    const urlParams = new URLSearchParams(queryString);
    return Object.fromEntries(urlParams.entries());
  }


  React.useEffect(() => {
    setPageStayTimeCB();
  }, [activeIndex]);


  React.useEffect(() => {
    const queryPramas = getQueryPramas();
    if(Object.keys(queryPramas).length === 0) {
      var elements = document.getElementsByClassName('menu-group');
      var frame = document.getElementById('iframe-wrap');
      frame.style.display = 'none';
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
        document.title = '如鱼数字空间 - 发出去，看得见';
      }
      var official = document.getElementById('official');
      official.style.display = 'block';
    }
    else{
      var official = document.getElementById('official');
      official.style.display = 'none';
      var app = document.getElementById('app');
      app.className = 'app';
      const fileId = queryPramas.fileid;
      const fileName = queryPramas.filename;
      const fileUrl = queryPramas.fileurl;
      const pageIndex = queryPramas.pageindex;
      const platform = queryPramas.platform;
      let weixinBoolean = false
      const ready = () => {
        console.log('miniprogram')

        if (window.__wxjs_environment === 'miniprogram') {
        console.log('miniprogram true')

          weixinBoolean = true;
        }
      };
      if (!window.WeixinJSBridge || !window.WeixinJSBridge.invoke) {
        console.log('WeixinJSBridge', window.WeixinJSBridge)

        document.addEventListener('WeixinJSBridgeReady', ready, false);
      } else {
        ready();
        window.WeixinJSBridge.on('onPageStateChange', res => {
          console.log('res is active', res.active);
        });
      }
      
      if (weixinBoolean) {
          console.log('微信小程序')
      }

      if(fileId && fileName && fileUrl) {
        // setFileId(fileId);
        init(fileId, fileName, fileUrl, pageIndex, platform);
      }
      else {
        console.error('Params Error', fileId, fileName, fileUrl, pageIndex, platform);
      }
    }
  }, []);

  // const saveAction = async (fileID) => {
  //   const res = await axios.post("http://82.157.243.144:6443/saveUserAction", {
  //     fileID: fileID,
  //     action: pageStayTime
  //   })
  //   console.log("saveAction", res);
  // }

  return (
    <div id='app'>
        <div id="iframe-wrap" className="mount-container"></div>
        <div id="official">
          <Den.Components.Display category={Den.Components.DisplayCategory.Hidden} type={Den.Components.DisplayType.Down} breakpoint={Den.Components.BreakpointType.Small}>
              <Den.Components.XCenter padding='2em'>
                <Den.Components.XBetween width='80vw' maxWidth='800px' gap='1em'>
                  <Den.Components.VSvg size={Den.Components.SizeType.ExtraLarge} borderRadius='0.2em'><LogoSvg /></Den.Components.VSvg>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} frontColor={Den.Components.ColorType.Dark} weight={Den.Components.FontWeightType.Light} caption='如鱼数字空间' />
                </Den.Components.XBetween>
              </Den.Components.XCenter>
              <Den.Components.XCenter padding='3em 0'>
                <Den.Components.VImage height='600px' category={Den.Components.ImageCategory.Scale} src='assets/images/hero.png' />
                <Den.Components.VText category={Den.Components.TextCategory.Article} content={<>
                  <Den.Components.VLabel padding='0.6em' size='super-large' weight={Den.Components.FontWeightType.Bold} caption='发出去，看得见' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption='了解客户所想，为客户提供真正需要的' />
                </>} />
              </Den.Components.XCenter>
              <Den.Components.YMiddle height='12em' gap='0.6em' backgroundColor={Den.Components.ColorType.Primary} cross={Den.Components.YCrossType.Center}>
                <Den.Components.VLabel frontColor={Den.Components.ColorType.White} size={Den.Components.SizeType.ExtraLarge} caption='我们的客户' />
                <Den.Components.X gap='1em'>
                  <Den.Components.VSvg size={Den.Components.SizeType.Large} frontColor={Den.Components.ColorType.Error}><LenovoSvg /></Den.Components.VSvg>
                  <Den.Components.VSvg size={Den.Components.SizeType.Large} frontColor={Den.Components.ColorType.Black}><SonySvg /></Den.Components.VSvg>
                  <Den.Components.VSvg size={Den.Components.SizeType.Large} frontColor={Den.Components.ColorType.Dark}><TempurSealySvg /></Den.Components.VSvg>
                </Den.Components.X>
              </Den.Components.YMiddle>
              <Den.Components.Y padding='6em 0' gap='6em'>
                <Den.Components.XCenter gap='12em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.Y gap='0.2em'>
                    <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} weight={Den.Components.FontWeightType.Bold} caption='文档库' />
                    <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption=' 微信聊天、本地文件、扫码下载 —— 打造您的专业文档资料库，让知识管理更高效！' />
                  </Den.Components.Y>
                  <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='600px' src='assets/images/library.png' />
                </Den.Components.XCenter>
                <Den.Components.XCenter gap='12em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='600px' src='assets/images/outbox.png' />
                  <Den.Components.Y gap='0.2em'>
                    <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} weight={Den.Components.FontWeightType.Bold} caption='发件箱' />
                    <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption='分享文档，洞察客户阅读动态 —— 掌握信息，赢得先机！' />
                  </Den.Components.Y>
                </Den.Components.XCenter>
                <Den.Components.XCenter gap='12em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.Y gap='0.2em'>
                    <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} weight={Den.Components.FontWeightType.Bold} caption='收件箱' />
                    <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption='随时随地查看文档，告别历史记录翻阅 —— 让工作更流畅，效率更上一层楼！' />
                  </Den.Components.Y>
                  <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='600px' src='assets/images/inbox.png' />
                </Den.Components.XCenter>
                <Den.Components.XCenter gap='12em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='600px' src='assets/images/share.png' />
                  <Den.Components.Y gap='0.2em'>
                    <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} weight={Den.Components.FontWeightType.Bold} caption='分享' />
                    <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption='获取更多的通过分享，洞察客户关注点 —— 连接客户，把握商机！' />
                  </Den.Components.Y>
                </Den.Components.XCenter>
                <Den.Components.XCenter gap='12em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.Y gap='0.2em'>
                    <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} weight={Den.Components.FontWeightType.Bold} caption='AI' />
                    <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption=' 让客户与文档对话，享受贴身助理般的体验 —— 智能互动，让服务更贴心！' />
                  </Den.Components.Y>
                  <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='600px' src='assets/images/ai.png' />
                </Den.Components.XCenter>
                <Den.Components.XCenter gap='12em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VImage padding='0.2em' borderStyle='solid' borderColor={Den.Components.ColorType.Placeholder} borderRadius='1em' borderWidth='1px' category={Den.Components.ImageCategory.Scale} height='500px' src='assets/images/pc.png' />
                  <Den.Components.Y gap='0.2em'>
                    <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} weight={Den.Components.FontWeightType.Bold} caption='PC支持' />
                    <Den.Components.VLabel size={Den.Components.SizeType.Normal} weight={Den.Components.FontWeightType.Thin} caption='微信小程序PC版——大屏幕，大视野，操作更自如！
' />
                  </Den.Components.Y>
                </Den.Components.XCenter>
              </Den.Components.Y>
              <Den.Components.XCenter height='12em' gap='1em' backgroundColor={Den.Components.ColorType.Black}>
                <Den.Components.VLabel frontColor={Den.Components.ColorType.White} caption='联系我们' />
                <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='80px' src='assets/images/promotion.png' />
              </Den.Components.XCenter>
              <Den.Components.Y padding='1em' gap='0.6em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.VLabel size={Den.Components.SizeType.Small} frontColor={Den.Components.ColorType.Dark} caption='©2024 如鱼智能' />
                <Den.Components.VLink category={Den.Components.LinkCategory.NewWindow} to='https://beian.miit.gov.cn'>
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} frontColor={Den.Components.ColorType.Dark25} caption='京ICP备2022028211号-2' />
                </Den.Components.VLink>
              </Den.Components.Y>
          </Den.Components.Display>
          <Den.Components.Display category={Den.Components.DisplayCategory.Hidden} type={Den.Components.DisplayType.Up} breakpoint={Den.Components.BreakpointType.Small}>
            <Den.Components.Y padding='2em 0' gap='1em' cross={Den.Components.YCrossType.Center}>
              <Den.Components.Y cross={Den.Components.YCrossType.Center} gap='0.6em'>
                <Den.Components.VSvg height='4em' width='4em' category={Den.Components.SvgCategory.Circle}><LogoSvg /></Den.Components.VSvg>
                <Den.Components.VLabel caption='如鱼数字空间' />
              </Den.Components.Y>
              <Den.Components.Y padding='2em 0' cross={Den.Components.YCrossType.Center} gap='1em'>
                <Den.Components.VText content={<>
                  <Den.Components.VLabel size={Den.Components.SizeType.ExtraLarge} caption='发出去，看得见' />
                </>} />
                <Den.Components.VImage height='400px' category={Den.Components.ImageCategory.Scale} src='assets/images/hero.png' />
              </Den.Components.Y>
            </Den.Components.Y>
            <Den.Components.Y>
              <Den.Components.VImage category={Den.Components.ImageCategory.FullSize} borderRadius='0' src='assets/images/wave.png' />
              <Den.Components.YMiddle height='12em' gap='0.6em' backgroundColor={Den.Components.ColorType.Primary} cross={Den.Components.YCrossType.Center}>
                <Den.Components.VLabel frontColor={Den.Components.ColorType.White} size={Den.Components.SizeType.Large} caption='我们的客户' />
                <Den.Components.X gap='1em'>
                  <Den.Components.VSvg frontColor={Den.Components.ColorType.Error}><LenovoSvg /></Den.Components.VSvg>
                  <Den.Components.VSvg frontColor={Den.Components.ColorType.Black}><SonySvg /></Den.Components.VSvg>
                  <Den.Components.VSvg frontColor={Den.Components.ColorType.Dark}><TempurSealySvg /></Den.Components.VSvg>
                </Den.Components.X>
              </Den.Components.YMiddle>
            </Den.Components.Y>
            <Den.Components.Y padding='4em 0' gap='2em'>
              <Den.Components.Y gap='1em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.Y gap='0.2em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} weight={Den.Components.FontWeightType.Bold} caption='文档库' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} weight={Den.Components.FontWeightType.Light} caption='微信聊天、本地文件、扫码下载打造您的专业文档资料库' />
                </Den.Components.Y>
                <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='400px' src='assets/images/library.png' />
              </Den.Components.Y>
              <Den.Components.Y gap='1em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.Y gap='0.2em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} weight={Den.Components.FontWeightType.Bold} caption='发件箱' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} weight={Den.Components.FontWeightType.Light} caption='分享文档，洞察客户阅读动态' />
                </Den.Components.Y>
                <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='400px' src='assets/images/outbox.png' />
              </Den.Components.Y>
              <Den.Components.Y gap='1em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.Y gap='0.2em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} weight={Den.Components.FontWeightType.Bold} caption='收件箱' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} weight={Den.Components.FontWeightType.Light} caption='随时随地查看文档，告别历史记录翻阅' />
                </Den.Components.Y>
                <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='400px' src='assets/images/inbox.png' />
              </Den.Components.Y>
              <Den.Components.Y gap='1em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.Y gap='0.2em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} weight={Den.Components.FontWeightType.Bold} caption='分享' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} weight={Den.Components.FontWeightType.Light} caption='获取更多的通过分享，洞察客户关注点' />
                </Den.Components.Y>
                <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='400px' src='assets/images/share.png' />
              </Den.Components.Y>
              <Den.Components.Y gap='1em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.Y gap='0.2em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} weight={Den.Components.FontWeightType.Bold} caption='AI' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} weight={Den.Components.FontWeightType.Light} caption='让客户与文档对话，享受贴身助理般的体验' />
                </Den.Components.Y>
                <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='400px' src='assets/images/ai.png' />
              </Den.Components.Y>
              <Den.Components.Y gap='1em' cross={Den.Components.YCrossType.Center}>
                <Den.Components.Y gap='0.2em' cross={Den.Components.YCrossType.Center}>
                  <Den.Components.VLabel size={Den.Components.SizeType.Large} weight={Den.Components.FontWeightType.Bold} caption='PC支持' />
                  <Den.Components.VLabel size={Den.Components.SizeType.Small} weight={Den.Components.FontWeightType.Light} caption='大屏幕，大视野，操作更自如！' />
                </Den.Components.Y>
                <Den.Components.VImage padding='0.6em' borderRadius='1em' borderStyle='solid' borderColor={Den.Components.ColorType.Placeholder} borderWidth='1px' category={Den.Components.ImageCategory.Scale} height='300px' src='assets/images/pc.png' />
              </Den.Components.Y>
            </Den.Components.Y>
            <Den.Components.XCenter height='12em' gap='1em' backgroundColor={Den.Components.ColorType.Black}>
              <Den.Components.VLabel frontColor={Den.Components.ColorType.White} caption='联系我们' />
              <Den.Components.VImage category={Den.Components.ImageCategory.Scale} height='80px' src='assets/images/promotion.png' />
            </Den.Components.XCenter>
            <Den.Components.Y padding='1em' gap='0.6em' cross={Den.Components.YCrossType.Center}>
              <Den.Components.VLabel size={Den.Components.SizeType.Small} frontColor={Den.Components.ColorType.Dark} caption='©2024 如鱼智能' />
              <Den.Components.VLink category={Den.Components.LinkCategory.NewWindow} to='https://beian.miit.gov.cn'>
                <Den.Components.VLabel size={Den.Components.SizeType.Small} frontColor={Den.Components.ColorType.Dark25} caption='京ICP备2022028211号-2' />
              </Den.Components.VLink>
            </Den.Components.Y>
          </Den.Components.Display>
        </div>
        <div className="return-btn" onClick={() => {
          wx.miniProgram.postMessage({ data: {
            ...pageStayTime,
            [activeIndex]: pageStayTime[activeIndex] ? pageStayTime[activeIndex] + (Date.now() - timerRec.current) / 1000 : (Date.now() - timerRec.current) / 1000,
          }});
          // wx.miniProgram.navigateBack();
          wx.miniProgram.switchTab({url: "/pages/index/index"})
        }}> {"< 返回首页"}</div>
        <div className='menu-group'>
            <div className="ai-btn" onClick={() => {
            wx.miniProgram.postMessage({ data: {
              ...pageStayTime,
              [activeIndex]: pageStayTime[activeIndex] ? pageStayTime[activeIndex] + (Date.now() - timerRec.current) / 1000 : (Date.now() - timerRec.current) / 1000,
            }});
            const queryPramas = getQueryPramas();
            const userId = queryPramas.userid;
            const fileId = queryPramas.fileid;
            const fileName = queryPramas.filename;
            // wx.miniProgram.navigateBack();
            const url = `/pages/ai/index?userId=${userId}&fileId=${fileId}&fileName=${fileName}&font=NULL&base64Background=NULL&shortcuts=${JSON.stringify(['总结文档', '作者是谁？', '联系方式？'])}`;
            wx.miniProgram.redirectTo({url});
          }}></div>
          <div className="download-btn" onClick={() => {
            const queryPramas = getQueryPramas();
            const fileId = queryPramas.fileid;
            wx.miniProgram.redirectTo({url: `/pages/index/index?fileId=${fileId}`});
          }}></div>
        </div>
    </div>
  );
}

// /pages/index/index
export default Main;

// const ActiveIndexIndicator = ({activeIndex}) => {
  
// }

// $(function() {
//   pushHistory();
//   window.addEventListener("popstate", function(e) {
//       //首页点击返回,直接关闭网页
//       WeixinJSBridge.call('closeWindow');
//       // !!!这里提交监控数据!!!
//   }, false);
//   function pushHistory() {
//       var state = {
//           title: "title",
//           url: "#"
//       };
//       window.history.pushState(state, state.title, state.url);
//   }
// })